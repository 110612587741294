import translator from "./third_party/vicowa-web-components/dist/utilities/translate.js";

(function() {
	const contentContainer = document.querySelector("#content");
	const mediaChange = window.matchMedia("(max-width: 600px)");
	const sideBar = document.querySelector("vicowa-sidebar");
	setTimeout(() => {
		const checkMedia = (p_Result) => {
			sideBar.expanded = !p_Result.matches;
		};
		checkMedia(mediaChange);
		mediaChange.addListener(checkMedia);
	}, 1000);

	document.querySelector("#toggle-expand").addEventListener("click", () => {
		sideBar.expanded = !sideBar.expanded;
	});

	Array.from(document.querySelectorAll("vicowa-button[location]")).forEach((p_Element) => {
		p_Element.onclick = () => {
			contentContainer.pageTitle = p_Element.getAttribute("string");
			contentContainer.location = p_Element.getAttribute("location");
		};
	});

	translator.addTranslationUpdatedObserver((p_Translator) => {
		if (p_Translator.language) {
			document.body.setAttribute("lang", p_Translator.language);
		}
	}, null);
	translator.language = document.body.getAttribute("lang");

	Array.from(document.querySelectorAll(".language-select")).forEach((p_Element) => {
		p_Element.addEventListener("click", () => {
			translator.language = p_Element.getAttribute("id");
		});
	});
})();
